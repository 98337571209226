.gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* background-color: blue; */
  gap: 0.3vh;
  /* height: 97%; */
  /* width: 99%; */
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;
  margin: 1vh;
  /* margin: 0.5%; */
}

@keyframes sineWaveOpacity {
  0% {
      opacity: 0.3;
  }
  50% {
      opacity: .5;
  }
  100% {
      opacity: 0.3;
  }
}
/* #FFF100  Yellow */




.img {
  /* min-width: 100%;
  min-height: 100%; */
  /* min-width: 100%; */
  /* height: 100%; */
  /* width: 100%; */
  /* object-fit: cover; */
  /* min-height: 90%; */
  object-fit: cover;

  min-width: 100%;
  min-height: 100%;
  /* background-color: #ffd54c; */
}

.one {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
  overflow: hidden;
  display: flex;
  align-items: center;
  /* object-fit: cover; */
  object-fit: cover;
}

.two {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  /* background-color: rgb(0, 162, 255); */
  overflow: hidden;
  display: flex;
  align-items: center;
}

.three {
  grid-column: 4 / 4;
  grid-row: 1 / 2;
  /* background-color: rgb(117, 230, 12); */
  overflow: hidden;
  display: flex;
  align-items: center;
}

.four {
  grid-column: 3 / 5;
  grid-row: 2 / 2;
  /* background-color: rgb(212, 27, 182); */
  overflow: hidden;
  display: flex;
  align-items: center;

}

h1 {
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 4vh;
  font-family: 'Playwrite BE VLG', sans-serif;
  font-family: "Great Vibes", cursive;
  text-decoration: none;
  overflow: hidden;


  /* font-family: "DM Serif Display", serif; */
  

}

h2 {
  color: black;
  text-align: center;
  font-size: 3.2vh;
  /* font-family: "DM Serif Display", serif; */
  font-family: "Great Vibes", cursive;

  text-decoration: none;
  


  /* z-index: 1; */
}
h3 {
  color: black;
  text-align: center;
  font-size: 2.5vh;
  /* font-family: "DM Serif Display", serif; */
  font-family: "Great Vibes", cursive;

  text-decoration: none;


  /* z-index: 1; */
}
h4 {
  color: black;
  font-size: 1.5vh;
  text-align: center;
  font-family: "DM Serif Display", serif;
  text-decoration: none;



  /* font-size: 3vw; */

  /* text-align: center; */
  /* z-index: 1; */
}

a {
  color: black;
  text-align: center;
  font-size: 1.5vh;
  display: block;
  margin: 1vh;
  /* font-family: "DM Serif Display", serif; */
  text-decoration: none;
  /* font-family: "Great Vibes", cursive; */
  /* font-optical-sizing: auto; */
  /* font-weight: <weight>; */
  /* font-style: normal; */
}

.infoImage{
height: 15vh;
}

.infoGrid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 10%;
  margin-right: 10%;
  grid-Gap: 1rem;
  font-Weight: normal;

}

@media (max-aspect-ratio: 1/1) {
  .infoGrid {
    grid-template-columns: 1fr;
    margin-left: 5%;  /* Optional: adjust margins for smaller screens */
    margin-right: 5%; /* Optional: adjust margins for smaller screens */
  }
}