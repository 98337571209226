.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    backdrop-filter: blur(3px);
    
}

.container:hover .overlay {
    opacity: 1;
    /* background-color: yellow; */

    
}

h1 {
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 24px;


}