.imageFrame {
    position: absolute;
    top: 0%;
    bottom: 0%;
    right: 0%;
    left: 0%;
    overflow: hidden;
}

.NextImage {
    position: absolute;
    font-size: 1.5em;
    margin-left: 1vw;
    margin-right: 1vw;

    text-decoration: underline;
    top: 50%;
    left: 0%;
    color: white;
    transform: translate(0%, -100%);
    background: none;
    border: none;
    cursor: grab;
}